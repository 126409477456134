import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"
import { RiArrowRightSLine } from "react-icons/ri"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { useIntl, Link } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ThePage = () => {
  const data = useStaticQuery(graphql`
    query senQuery {
      img1: file(relativePath: { eq: "sensory01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 695) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img2: file(relativePath: { eq: "sensory02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 695) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img3: file(relativePath: { eq: "sensory03.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 695) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img4: file(relativePath: { eq: "sensory04.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 695) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  const intl = useIntl()
  const tt = key => {
    if (intl.messages[key] === undefined) {
      return key
    } else if (intl.messages[key] === "") {
      return ""
    } else {
      return intl.formatMessage({ id: key })
    }
  }

  return (
    <Layout>
      <SEO
        title="seo.title.SenIntTherapy"
        description="seo.desc.SenIntTherapy"
      />
      <div className="content-master">
        <Container>
          <Row>
            <Col xs={12}>
              <h3>
                <div
                  css={css`
                    text-align: center;
                    font-weight: bold;
                  `}
                >
                  {tt("pages.sensory.title1")}
                </div>
              </h3>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col sm={8} className="pb-3">
              <div className="text-dark">
                <div>
                  {tt("pages.sensory.desc1_1")}
                  <a href="https://content.wpspublish.com/Submitted-Content/pdfs/SI_Directory.pdf">
                    wpspublish
                  </a>
                  {tt("pages.sensory.desc1_2")}
                </div>
              </div>
            </Col>
            <Col>
              <Img
                className="rounded mx-auto d-block border border-warning"
                fluid={data.img1.childImageSharp.fluid}
              />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col sm={8} className="pb-3">
              <div className="text-dark">{tt("pages.sensory.desc1_3")}</div>
            </Col>
            <Col>
              <Img
                className="rounded mx-auto d-block border border-warning pb-3"
                fluid={data.img2.childImageSharp.fluid}
              />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col sm={8} className="pb-3">
              <div className="d-flex flex-column">
                <h5 className="font-weight-bold">
                  {tt("pages.sensory.title2")}
                </h5>
                <div className="text-dark">{tt("pages.sensory.desc2")}</div>
              </div>
            </Col>
            <Col>
              <Img
                className="rounded mx-auto d-block border border-warning pb-3"
                fluid={data.img3.childImageSharp.fluid}
              />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col sm={8} className="pb-3">
              <div className="d-flex flex-column">
                <h5 className="font-weight-bold">
                  {tt("pages.sensory.title3")}
                </h5>
                <div className="text-dark">{tt("pages.sensory.desc3")}</div>
              </div>
              <h5 className="font-weight-bold pt-4">
                {tt("pages.sensory.title4")}
              </h5>
              <div className="text-dark">{tt("pages.sensory.desc4")}</div>
            </Col>
            <Col>
              <Img
                className="rounded mx-auto d-block border border-warning pb-3"
                fluid={data.img4.childImageSharp.fluid}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default ThePage
